import React from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";

import PostDetails from "../sections/blog/PostDetails";
import Comments from "../sections/blog/Comments";

const BlogDetails = ({ data }) => {
  const post = data.markdownRemark;

  if (!post) return null;

  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">{post.frontmatter.title}</Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>
                    <Link to="/">{post.frontmatter.date}</Link>
                  </Text>
                  <Text mr={3}>
                    <Link to="/">{post.frontmatter.category}</Link>
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8" className="mb-5">
                <PostDetails content={post.html} />
              </Col>
            </Row>
          </Container>
        </Section>
        {false && <Comments />}
      </PageWrapper>
    </>
  );
};
export default BlogDetails;

export const query = graphql`
  query BlogQuery($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        date
        author
        path
        category
      }
    }
  }
`;
